import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

async function fetchApp() {
  const response = await fetch("/firebase.json");
  if (!response.ok) {
    throw new Error("Failed to fetch configuration");
  }

  const result = await response.json();
  return result;
}

const firebaseConfig = await fetchApp();

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBPbmsN0ZoUdiZRyUdbdgpdvNOjO8Bc_Tw",
//   authDomain: "bitcoin-trading-c.firebaseapp.com",
//   projectId: "bitcoin-trading-c",
//   storageBucket: "bitcoin-trading-c.appspot.com",
//   messagingSenderId: "964187006500",
//   appId: "1:964187006500:web:9b5988e9c0e4ede31adab1",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDtmNsjSnruKS6ZCs3jwqec0QMqGqGKQWk",
//   authDomain: "finance-v2-e1682.firebaseapp.com",
//   projectId: "finance-v2-e1682",
//   storageBucket: "finance-v2-e1682.appspot.com",
//   messagingSenderId: "133386766551",
//   appId: "1:133386766551:web:e0236afa4a4dc881e20892",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
